import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProgramConfig, ProgramLpa } from '@tdf/rtpca-models';

export const programConfigApiActions = createActionGroup({
  source: 'Program Config API',
  events: {
    'Load Program Config': emptyProps(),
    'Load Program Config Success': props<{ programConfigs: ProgramConfig[] }>(),
    'Load Program Config Failure': props<{ error: string }>(),
    'Save Program Config': props<{ programConfig: ProgramConfig }>(),
    'Save Program Config Success': props<{ programConfig: ProgramConfig }>(),
    'Save Program Config Failure': props<{ error: string }>(),
    'Delete Program Config': props<{ programConfigId: string }>(),
    'Delete Program Config Success': props<{ programConfigId: string }>(),
    'Delete Program Config Failure': props<{ error: string }>(),
    'Load Available LPA Programs': emptyProps(),
    'Load Available LPA Programs Success': props<{ lpaPrograms: ProgramLpa[] }>(),
    'Load Available LPA Programs Failure': props<{ error: string }>(),
  },
});
