import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { ProgramConfig, ProgramLpa } from '@tdf/rtpca-models';
import { programConfigApiActions } from './actions';

export interface State {
  programConfigs: ProgramConfig[];
  lpaPrograms: any[];
  loading: boolean;
  error: string;
}

export const initialState: State = {
  programConfigs: [],
  lpaPrograms: [],
  loading: false,
  error: '',
};

export const programConfigFeature = createFeature({
  name: 'programConfig',
  reducer: createReducer(
    initialState,
    on(programConfigApiActions.loadProgramConfig, (state) => ({
      ...state,
      loading: true,
    })),
    on(
      programConfigApiActions.loadProgramConfigSuccess,
      (state, { programConfigs }) => ({
        ...state,
        programConfigs,
        loading: false,
      })
    ),
    on(programConfigApiActions.loadProgramConfigFailure, (state) => ({
      ...state,
      loading: false,
      error: 'Failed to load program configs',
    })),
    on(programConfigApiActions.saveProgramConfig, (state) => ({
      ...state,
      loading: true,
    })),
    on(
      programConfigApiActions.saveProgramConfigSuccess,
      (state, { programConfig }) => ({
        ...state,
        programConfigs: [...state.programConfigs, programConfig],
        loading: false,
      })
    ),
    on(programConfigApiActions.deleteProgramConfig, (state) => ({
      ...state,
      loading: true,
    })),
    on(programConfigApiActions.deleteProgramConfigSuccess, (state) => ({
      ...state,
      loading: false,
    })),
    on(programConfigApiActions.loadAvailableLPAPrograms, (state) => ({
      ...state,
      loading: true,
    })),
    on(
      programConfigApiActions.loadAvailableLPAProgramsSuccess,
      (state, { lpaPrograms }) => ({
        ...state,
        lpaPrograms,
        loading: false,
      })
    )
  ),
});

export const {
  selectLoading,
  selectProgramConfigs,
  selectLpaPrograms,
  selectProgramConfigState,
} = programConfigFeature;

export const selectGroupedLPAProgram = createSelector(
  selectProgramConfigState,
  (state) => {
    const groupedLpaPrograms = state.lpaPrograms.reduce((acc, lpaProgram) => {
      if (acc[lpaProgram.periodName]) {
        acc[lpaProgram.periodName].push(lpaProgram);
      } else {
        acc[lpaProgram.periodName] = [lpaProgram];
      }
      return acc;
    }, {});
    return Object.keys(groupedLpaPrograms).map((key) => {
      return { name: key, items: groupedLpaPrograms[key] } as {
        name: string;
        items: ProgramLpa[];
      };
    });
  }
);
